import * as React from 'react';
import Fab from '@mui/material/Fab';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { DigitInput } from '@/components/Inputs';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/auth';
import { useTimer } from '@/hooks/useTimer';

export const SmsCodeForm = (props) => {
  const [code, setCode] = React.useState('');
  const [timerStartDate, setTimerStartDate] = React.useState(Date.now());
  const { loginMutation, requestCodeMutation } = useAuth();
  const { seconds } = useTimer(timerStartDate + 10 * 1000);
  const { state } = useLocation();
  const navigate = useNavigate();
  const phoneNumber = state?.phoneNumber;

  React.useEffect(() => {
    loginMutation.reset();
  }, []);

  React.useEffect(() => {
    if (code.length === 6) {
      loginMutation.mutate(
        { code, phoneNumber },
        { onSuccess: () => navigate('/block/live'), onError: () => resetDigits() }
      );
    }
  }, [code]);

  const resetDigits = () => setCode('');

  const handleResendCode = () => {
    setTimerStartDate(Date.now());
    resetDigits();
    requestCodeMutation.mutate({ phoneNumber });
  };

  const handleBack = () => {
    navigate('/auth/login', { state: { phoneNumber } });
  };

  if (!phoneNumber) return <Navigate to='/auth/login' />;

  return (
    <>
      {loginMutation.isError && (
        <Alert severity='error' sx={{ textAlign: 'left' }}>
          The passcode you have entered is incorrect
        </Alert>
      )}
      {loginMutation.isLoading && (
        <Box p={10} align='center'>
          <LinearProgress />
        </Box>
      )}
      <Typography>
        Enter the 6-digit code sent to you at xxx xxx-x
        {phoneNumber.substr(phoneNumber.length - 3)}
      </Typography>
      <DigitInput
        digit={6}
        setValue={setCode}
        disabled={loginMutation.isLoading}
      />
      <LoadingButton
        variant='contained'
        loading={requestCodeMutation.isLoading}
        disabled={seconds >= 0}
        onClick={handleResendCode}
      >
        Resend code {seconds < 0 ? '' : `(0:${seconds > 9 ? '' : '0'}${seconds})`}
      </LoadingButton>
      <Fab onClick={handleBack}>
        <ArrowBackIcon />
      </Fab>
    </>
  );
};
