import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

export const SingleSelectionChipList = (props) => {
  const { chips, selectedChip, setSelectedChip } = props;

  const handleChipClick = (chip) => () => {
    setSelectedChip(chip);
  };

  return (
    <Grid container spacing={1}>
      {chips.map((chip) => (
        <Grid key={chip} item xs={6}>
          <Chip
            key={chip}
            label={chip}
            color='primary'
            variant={selectedChip === chip ? 'filled' : 'outlined'}
            onClick={handleChipClick(chip)}
            sx={{ width: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
