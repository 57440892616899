import axios from 'axios';

import { ADMIN_API_URL, USER_API_URL } from '@/config';
import storage from '@/utils/storage';

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.Token = `${token}`;
  }
  config.headers.EnvironmentToken = process.env.REACT_APP_SHOPICAR_ENVIRONMENT_TOKEN;
  return config;
}

export const adminApi = axios.create({
  baseURL: ADMIN_API_URL,
});

export const userApi = axios.create({
  baseURL: USER_API_URL,
});

[adminApi, userApi].map((api) => {
  api.interceptors.request.use(authRequestInterceptor);
  api.interceptors.response.use((response) => {
    return response.data;
  });
});
