import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BrandingLogo } from '@/components/Elements';
import { Head } from '@/components/Head';
import { Footer } from '@/components/Footer';
import { useTranslation } from 'react-i18next';

const contentWrapperStyle = {
  width: 400,
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media only screen and (max-width: 400px)': {
    width: '90%',
  },
};
export const Layout = ({ title, children }) => {
  
  const { t } = useTranslation();
  return (
    <>
      <Head title={title} />
      <Box display='flex' flexGrow={1}>
        <Box my={6} sx={contentWrapperStyle}>
          <Box mb={2} display='flex' justifyContent='center'>
            <BrandingLogo />
          </Box>
          <Paper sx={{ padding: 3, textAlign: 'center' }}>
            <Stack spacing={3}>
              <Typography variant='h4' fontWeight='bold' color='primary'>
                {t('Sign in')}
              </Typography>
              {children}
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
