import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { roundModulo10 } from '@/utils/math';

export const RangeSlider = (props) => {
  const min = props.range[0];
  const max = props.range[1];
  const step = props.step ?? roundModulo10(Math.abs(max - min) * 0.03);
  const [minValue, setMinValue] = React.useState(min);
  const [maxValue, setMaxValue] = React.useState(max);

  const handleSliderChangeCommitted = (event, range) => {
    props.setMinValue(range[0]);
    props.setMaxValue(range[1]);
  };

  const handleSliderChange = (event, range) => {
    setMinValue(range[0]);
    setMaxValue(range[1]);
  };

  const handleInputChange = (setter) => (event) => {
    setter(parseInt(event.target.value));
  };

  return (
    <Box px={3}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <TextField
            id='min'
            label='Min'
            variant='outlined'
            size='small'
            value={minValue}
            onChange={handleInputChange(setMinValue)}
            onBlur={(event) =>
              props.setMinValue(Math.max(parseInt(event.target.value), min))
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='max'
            label='Max'
            variant='outlined'
            size='small'
            value={maxValue}
            onChange={handleInputChange(setMaxValue)}
            onBlur={(event) =>
              props.setMaxValue(Math.min(parseInt(event.target.value), max))
            }
          />
        </Grid>
      </Grid>
      <Slider
        value={[minValue, maxValue]}
        min={min}
        max={max}
        marks={[
          { value: min, label: props.formatter ? props.formatter.format(min) : min },
          { value: max, label: props.formatter ? props.formatter.format(max) : max },
        ]}
        step={step}
        disableSwap
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        valueLabelDisplay='auto'
      />
    </Box>
  );
};
