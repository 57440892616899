import * as React from 'react';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';

export const DigitInput = (props) => {
  const { digit, disabled, setValue } = props;
  const [code, setCode] = React.useState('');
  const mobile = useMediaQuery('(max-width:400px)');

  const handleChange = (event) => {
    const value = event.target.value;
    const reg = new RegExp(`^\\d{0,${digit}}$`);
    if (reg.test(value)) {
      setCode(value);
    }
  };

  React.useEffect(() => {
    setValue(code);
  }, [code, setValue]);

  return (
    <TextField
      value={code}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{
        maxLength: digit,
        inputMode: 'numeric',
        style: { textAlign: 'center', letterSpacing: mobile ? '0.2em' : '0.5em' },
        autoComplete: 'one-time-code'
      }}
      sx={mobile ? { '& .MuiInputBase-input': { padding: '2px 0px' } } : {}}
    />
  );
};
