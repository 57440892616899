import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en/en-ca.json';
import fr from './translations/fr/fr-ca.json';

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fr', // par défaut
    fallbackLng: 'en', // en cas de traduction manquante
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18next.languages = ['en', 'fr'];
export default i18next;
