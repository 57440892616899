import { Link } from 'react-router-dom';

export const BrandingLogo = (props) => {
  return (
    <Link to='/'>
      <img
        alt='logo'
        src='https://shopicar-images.s3.amazonaws.com/0/Logo-ShopiTrade-BLUE-200px.png'
        {...props}
      />
    </Link>
  );
};
